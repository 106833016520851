import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import GenericPage from '../components/genericPage'

import content from '../json/credits.json'

const CreditsPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/credits-et-mentions-legales">
      <SEO 
        title="Crédits et mentions"
        description="Crédits et mentions légales de l'Observatoire."
        path="/credits-et-mentions-legales" />

      <Breadcrumb url="/credits-et-mentions-legales" label="Crédits & mentions légales" type="credits" /> 

      <GenericPage slug="credits" content={content} />
    </Layout>
  )
  }

export default CreditsPage

